import React from "react";
import { CSVLink } from "react-csv";
import moment from "moment";

const CsvComponent = ({ data }) => {

    if (!data) {
        return null;
    }

    const headers = [
        { label: "Project Name", key: "projectName" },
        { label: "Task Description", key: "description" },
        { label: "Start Date", key: "startDate" },
        { label: "End Date", key: "endDate" },
        { label: "Hours", key: "hours" },
    ];

    const csvData = data.reduce((acc, project) => {
        const tasks = project.tasks.map((task) => {
            const startDate = moment(task.startDate);
            const endDate = moment(task.endDate);
            const duration = moment.duration(endDate.diff(startDate));
            const hours = duration.asHours().toFixed(2);

            return {
                projectName: project.projectName,
                description: task.description || "No description",
                startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
                endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
                hours: hours,
            };
        });

        return [...acc, ...tasks];
    }, []);

    const totalHours = csvData.reduce((acc, task) => acc + parseFloat(task.hours), 0);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
        }}>
            <div style={{

                height: "80%",
                width: "80v%",
                marginLeft: "10%",
                marginRight: "10%",
                marginTop: "3%",
                overflow: "scroll"

            }}>
                <table className="csv-table">
                    <thead style={{
                        position: "sticky",
                        top: "0"
                    }}>
                        <tr>
                            {headers.map((header) => (
                                <th key={header.key}>{header.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tfoot style={{
                        position: "sticky",
                        bottom: "0"
                    }}>
                        <tr>
                            <th key="projectName">Total</th>
                            <th key="plhdr1"></th>
                            <th key="plhdr2"></th>
                            <th key="plhdr3"></th>
                            <th style={{
                                textAlign: "left",
                            }} key="totalHours">{totalHours}</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        {csvData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.projectName}</td>
                                <td>{item.description}</td>
                                <td>{item.startDate}</td>
                                <td>{item.endDate}</td>
                                <td>{item.hours}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{
                marginTop: "20px",
            }}>
                <CSVLink data={[...csvData,{
                    projectName: "Total",
                    description: "",
                    startDate: "",
                    endDate: "",
                    hours: totalHours,
                }]} headers={headers}>
                    <button className="button">Download CSV</button>
                </CSVLink>
            </div>
        </div>
    );
};

export default CsvComponent;