import axios from 'axios';
import { useEffect, useState } from 'react';
import CsvComponent from './csvTable';

function App() {

  const [data, setData] = useState(null);

  function parseJson(jsonDoc) {
    const json = JSON.parse(jsonDoc).timesheet;
    const projects = json.projects.project.map((project) => {
      return {
        name: project.name._text,
        projectID: project.projectId._text,
      }
    });
    const projectWData = projects.map((project) => {
      const tasks = [];
      json.tasks.task.forEach((task) => {
        if (task.projectId._text === project.projectID) {
          tasks.push({
            description: task.description._text ? task.description._text : "No description",
            taskID: task.taskId._text,
            startDate: task.startDate._text,
            endDate: task.endDate._text
          })
        }
      })
      return {
        projectName: project.name,
        tasks: tasks
      }

    })

    return projectWData;

  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://orelavoro.stefanoputelli.it/api");
        const jsonDoc = response.data.fileContent;
        setData(parseJson(jsonDoc));
      } catch (e) {
        console.log("Error: " + e)
      }
    };
    fetchData();
  }, []);

  return (
    <div className="App">

      <CsvComponent data={data} />
    </div>
  );
}

export default App;
